<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader />
      <TheAside />
      <div class="c-body">
        <main class="c-main">
          <div class="">
            <CToaster
              v-for="(toaster, toasterKey) in toasters"
              :position="toasterKey"
              :key="'toaster' + toasterKey"
            >
              <template v-for="(toast, key) in toaster">
                <CToast :key="'toast' + key" :show="true" v-bind="toast">
                  <i :class="mtdGetClass(toast.color)"></i> {{ toast.content }}
                </CToast>
              </template>
            </CToaster>
          </div>
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
    <Loading v-show="loading"></Loading>
  </div>
</template>

<script>
import TheAside from "./TheAside";
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

import CToaster from "../components/shared/Toast/CToaster.vue";
import CToast from "../components/shared/Toast/CToast.vue";

import Loading from "../components/loading/Modal.vue";

import { bus } from "../main";

export default {
  name: "TheContainer",
  components: {
    TheAside,
    TheSidebar,
    TheHeader,
    TheFooter,
    Loading,
    CToast,
    CToaster,
  },
  data() {
    return {
      loading: false,
      alert: {
        dismissSecs: 3,
        dismissCountDown: 0,
        color: "info",
        message: "",
      },
      toasts: [],
    };
  },
  created() {
    bus.$on("loading", (data) => {
      this.loading = data;
    });
    bus.$on("alert", (item) => {
      // this.alert.color = item.color;
      // this.alert.dismissCountDown = this.alert.dismissSecs;
      // this.alert.message = item.message;
      this.toasts.push({
        autohide: 2000,
        position: "top-center",
        content: item.message,
        color: item.color,
      });
    });
  },
  computed: {
    toasters() {
      return this.toasts.reduce((toasters, toast) => {
        toasters[toast.position] = toasters[toast.position] || [];
        toasters[toast.position].push(toast);
        return toasters;
      }, {});
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showDismissibleAlerts() {
      ["alert1", "alert2"].forEach((alert) => (this[alert] = true));
    },
    mtdGetClass(color) {
      switch (color) {
        case "success":
          return "fas fa-check-circle";
          break;
        case "danger":
          return "fas fa-times";
          break;
        case "warning":
          return "fas fa-exclamation-circle";
          break;
        case "info":
          return "fas fa-info";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/**  */
.alert {
  position: fixed;
  top: 100px;
  left: 1050px;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}
</style>
